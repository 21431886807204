export const ROOT_PATH = '/';
export const BASE_ASSETS_PATH = 'projectAssets/:projectId/:projectName';
export const MANAGE_ASSET_PATH = BASE_ASSETS_PATH + '/manageAsset';
export const UPLOAD_ASSET_PATH = BASE_ASSETS_PATH + '/uploadAssets';
export const DOWNLOAD_ASSETS_PATH = BASE_ASSETS_PATH + '/downloadAssets';
export const EDIT_ASSET_PATH = BASE_ASSETS_PATH + '/editAsset';
export const EDIT_ASSETS_PATH = BASE_ASSETS_PATH + '/editAssets';
export const MAIN_APP = 'mainApp';
export const MAIN_APP_AUTHORISED = 'mainAppAuthorised';
export const MAIN_APP_AUTHORIZE = 'mainAppAuthorize';
export const NAV_BAR_ID = 'AssetCollectorNav';

// Project Assets Table Constants
export const ASSET_COLLECTOR_CELL_VALUE = 'Asset collector';
export const ASSET_SERVICE_CELL_VALUE = 'Asset service';
// TODO: Reassign to 'ErrorSource'
export const ERROR_SOURCE_KEY = 'AssetSource';
export const ASSET_STATUS_KEY = 'AssetStatus';
export const BACKEND_VALIDATION_SERVICE = 'BACKEND_VALIDATION_SERVICE';
export const ASSET_SERVICE = 'ASSET_SERVICE';
export const ERROR_CODES_KEY = 'ErrorCodes';
export const ERROR_KEY = 'Error';
export const LOCATION_KEY = 'Location';
export const PFHO_DATE_KEY = 'PfhoDateInEpochSeconds';
export const CREATED_BY_KEY = 'CreatedBy';
export const UPDATED_BY_KEY = 'LastUpdatedBy';
export const CREATED_ON_KEY = 'CreatedDateInEpochSeconds';
export const UPDATED_ON_KEY = 'UpdatedDateInEpochSeconds';
export const LOCATION_CODE_KEY = 'locationCode';
export const LOCATION_DESC_KEY = 'locationDescription';
export const PROPERTY_FILTER_KEY = 'propertyKey';

export const errorSourceLabelMap = {
  [BACKEND_VALIDATION_SERVICE]: ASSET_COLLECTOR_CELL_VALUE,
  [ASSET_SERVICE]: ASSET_SERVICE_CELL_VALUE,
};

// Manage Asset Page constants

// TODO: Reassign to 'AssetSource'
export const ASSET_SOURCE_KEY = 'Source';
export const ASSET_COLLECTOR_SOURCE = 'ASSET_COLLECTOR';
export const ASSET_SERVICE_SOURCE = 'ASSET_SERVICE';
export const ASSET_COLLECTOR_SOURCE_LABEL = 'Asset Collector';
export const ASSET_SERVICE_SOURCE_LABEL = 'Asset Service/EAM';
export const DIFFERENT_SITE_ASSET_STATUS = 'Different Site';
export const SEARCH_PARAM_FIELDS = {
  ASSET_ID: 'assetId',
  ASSIGNEE: 'assignee',
  CATEGORY: 'category',
  FBN: 'fbn',
  LOCATION: 'location',
  MANUFACTURER: 'manufacturer',
  MODEL: 'model',
  OWNED_BY: 'ownedBy',
  PARENT_ASSET_ID: 'parentAssetId',
  PFHO_DATE: 'pfho',
  SERIAL_NUMBER: 'serialNumber',
  TAG_PLATE_ID: 'tagPlateId',
  YELLOW_ASSET_TAG_ID: 'yellowAssetTagId',
};

export enum StatusIndicatorType {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
  IN_PROGRESS = 'in-progress',
  LOADING = 'loading',
}

export enum QueryStatus {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  QUERYING = 'QUERYING',
  NEW_ASSET = 'NEW_ASSET',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
  EDIT_FROM_TABLE = 'EDIT_FROM_TABLE',
  DEFAULT = 'DEFAULT',
  INCORRECT_ID = 'INCORRECT_ID',
  EXISTING_ASSET = 'EXISTING_ASSET',
  DIFFERENT_SITE = 'DIFFERENT_SITE',
  DRAFT_ASSET = 'DRAFT_ASSET',
  STAGING_DISPOSED_ASSET = 'STAGING_DISPOSED_ASSET',
}

const queryStatusLabelMap = new Map<QueryStatus, (source?: string) => string>([
  [
    QueryStatus.INTERNAL_ERROR,
    () => 'Unknown error. Fill out the fields below and the system will determine if it is a create/update case later.',
  ],
  [QueryStatus.QUERYING, () => 'Querying for existing assets'],
  [QueryStatus.NEW_ASSET, () => 'No existing asset found. Fill in fields below to create a new asset.'],
  [QueryStatus.CHANGE_REQUEST, () => 'Asset change request details below'],
  [QueryStatus.EDIT_FROM_TABLE, () => 'Editing asset from table'],
  [QueryStatus.DEFAULT, () => 'Input yellow asset tag ID to start query for existing assets'],
  [QueryStatus.INCORRECT_ID, () => 'Yellow asset tag ID must be a 10 digit number'],
  [
    QueryStatus.EXISTING_ASSET,
    source => `Existing asset was found in ${source}. Change any fields below to make an edit to the asset.`,
  ],
  [
    QueryStatus.DIFFERENT_SITE,
    () => 'Existing asset was found under a different site. Edits are not allowed unless it is through that site.',
  ],
  [
    QueryStatus.STAGING_DISPOSED_ASSET,
    () => 'Existing asset found in staging or disposed state. Edits are not allowed on this asset.',
  ],
  [
    QueryStatus.DRAFT_ASSET,
    () =>
      'Existing asset is currently being checked against system requirements. Try again when validation is complete.',
  ],
]);

export const getStatusMessage = (status: QueryStatus, source?: string): string => {
  const messageFunction = queryStatusLabelMap.get(status);
  return messageFunction ? messageFunction(source) : '';
};

const queryStatusIconMap: Record<QueryStatus, StatusIndicatorType> = {
  [QueryStatus.INTERNAL_ERROR]: StatusIndicatorType.ERROR,
  [QueryStatus.QUERYING]: StatusIndicatorType.IN_PROGRESS,
  [QueryStatus.NEW_ASSET]: StatusIndicatorType.SUCCESS,
  [QueryStatus.CHANGE_REQUEST]: StatusIndicatorType.INFO,
  [QueryStatus.EDIT_FROM_TABLE]: StatusIndicatorType.INFO,
  [QueryStatus.DEFAULT]: StatusIndicatorType.INFO,
  [QueryStatus.INCORRECT_ID]: StatusIndicatorType.INFO,
  [QueryStatus.EXISTING_ASSET]: StatusIndicatorType.SUCCESS,
  [QueryStatus.DIFFERENT_SITE]: StatusIndicatorType.ERROR,
  [QueryStatus.STAGING_DISPOSED_ASSET]: StatusIndicatorType.ERROR,
  [QueryStatus.DRAFT_ASSET]: StatusIndicatorType.ERROR,
};

export const getStatusMessageIcon = (status: QueryStatus) => {
  return queryStatusIconMap[status];
};

export enum AssetServiceStatus {
  ASN = 'ASN Provided',
  VALIDATED_IN_SERVICE = 'Validated - In Service',
  VALIDATED_IN_STORE = 'Validated - In Store',
  IN_SERVICE = 'In Service',
  IN_STORE = 'In Store',
  STAGING = 'Staging',
  DISPOSED = 'Disposed',
}

export enum AssetCollectorStatus {
  BACKEND_VALIDATION = 'Checking System Requirements',
}
